var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900" },
          on: {
            "click:outside": function($event) {
              return _vm.closeWarehouseCreate()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-4",
                          attrs: {
                            disabled: _vm.totalStock === _vm.setting.maxStocks,
                            color: "primary",
                            rounded: "",
                            depressed: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [_vm._v(" mdi-plus-circle ")]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("warehouse.btn.addWarehouse")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c(
                    "div",
                    { staticClass: "close-popup" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeWarehouseCreate()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("title-label", {
                    attrs: { label: _vm.$t("warehouse.label.addWarehouse") }
                  }),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "mt-4",
                      attrs: { readonly: _vm.loading, "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [_c("warehouse-form", { attrs: { form: _vm.form } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeWarehouseCreate()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("warehouse.btn.cancel")) + " "
                          )
                        ]
                      ),
                      _c("save-change-button", {
                        staticClass: "mr-1",
                        attrs: {
                          state: _vm.state,
                          disabled: !_vm.valid,
                          label: _vm.$t("warehouse.btn.confirm")
                        },
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }