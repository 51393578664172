<template>
  <div class="warehouse">
    <div class="d-flex align-center">
      <header-label
        :label="$t('warehouse.header')"
        prepend-icon="$warehouseHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('warehouse.label.moreOption')"
          prepend-icon="$settingTitle" />
        <toggle-form
          v-model="setting.shareInventoryBetweenStock"
          :text="$t('warehouse.toggle.shareInventoryBetweenStock')"
          :state="state.shareInventoryBetweenStock"
          @input="openStockAlert()" />
        <v-dialog
          v-model="dialog"
          max-width="450"
          persistent>
          <v-card>
            <v-card-text class="pt-4">
              <v-row no-gutters>
                <v-col
                  cols="3"
                  class="text-center">
                  <v-icon
                    color="primary"
                    size="60">
                    mdi-information-outline
                  </v-icon>
                </v-col>
                <v-col class="pt-4">
                  <p class="primary--text subtitle-1">
                    {{ $t('warehouse.alert.label') }}
                  </p>
                  <p>
                    {{ $t('warehouse.alert.moveToMainStock') }}
                  </p>
                  <v-form ref="form">
                    <v-checkbox
                      :label="$t('warehouse.textField.showAlert.label')"
                      :rules="stockAletRules"
                      required />
                    <div class="text-right">
                      <v-btn
                        color="primary"
                        text
                        @click="closeStockAlert()">
                        {{ $t('warehouse.btn.cancel') }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        rounded
                        depressed
                        @click="submitStockAlert()">
                        {{ $t('warehouse.btn.confirm') }}
                      </v-btn>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-text>
        <div v-if="activeProduct">
          <div class="d-flex mb-4">
            <title-label
              :label="$t('warehouse.label.productInWarehouse')"
              prepend-icon="mdi-warehouse" />
            <span class="primary--text ml-2 mt-1">
              {{ }}
            </span>
            <v-icon color="primary">
              mdi-chevron-left
            </v-icon>
            <a
              class="primary--text mt-1"
              @click="backToWarehouse()">
              {{ $t('warehouse.btn.back') }}
            </a>
          </div>
          <v-row
            class="mb-4"
            no-gutters>
            <v-col cols="5">
              <v-text-field
                v-model="search"
                :placeholder="$t('warehouse.search.placeholder')"
                prepend-inner-icon="mdi-magnify"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col class="d-flex justify-end">
              <product-create
                :stock-id="stockId"
                @submit="refetchStockProduct()">
              </product-create>
              <product-import
                :stock-id="stockId"
                @submit="refetchStockProduct()">
              </product-import>
            </v-col>
          </v-row>
        </div>
        <div
          v-else
          class="d-flex">
          <title-label
            :label="$t('warehouse.label.warehouseManage')"
            prepend-icon="$stockTitle" />
          <warehouse-create @submit="refetchStock()" />
        </div>
        <product-simple-table
          v-if="activeProduct"
          ref="StockProductSimpleTable"
          :search="search"
          :stock-id="stockId">
        </product-simple-table>
        <warehouse-data-table
          v-else
          ref="StockDataTable"
          :active-product.sync="activeProduct"
          :stock-id.sync="stockId">
        </warehouse-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import WarehouseCreate from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseCreate'
import WarehouseDataTable from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseDataTable'
import ProductCreate from '@/views/ShopSetting/Pages/Warehouse/components/Product/ProductCreate'
import ProductImport from '@/views/ShopSetting/Pages/Warehouse/components/Product/ProductImport'
import ProductSimpleTable from '@/views/ShopSetting/Pages/Warehouse/components/Product/ProductSimpleTable'
import { GET_SETTING_STOCK } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Warehouse',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_STOCK
    })
  },
  components: {
    WarehouseCreate,
    WarehouseDataTable,
    ProductCreate,
    ProductImport,
    ProductSimpleTable
  },
  data () {
    return {
      activeProduct: false,
      setting: {},
      stockId: null,
      search: '',
      dialog: false,
      state: {
        shareInventoryBetweenStock: 'ready'
      },
      stockAletRules: [
        (v) => !!v || this.$t('warehouse.textField.showAlert.error')
      ]
    }
  },
  methods: {
    openStockAlert () {
      if (this.setting.shareInventoryBetweenStock) {
        this.dialog = true
      } else {
        this.submit('shareInventoryBetweenStock')
      }
    },
    closeStockAlert () {
      this.dialog = false
      this.setting.shareInventoryBetweenStock = false
    },
    submitStockAlert () {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.submit('shareInventoryBetweenStock')
      }
    },
    backToWarehouse () {
      this.activeProduct = false
      this.search = ''
    },
    refetchStock () {
      this.$refs.StockDataTable.refetch()
    },
    refetchStockProduct () {
      this.$refs.StockProductSimpleTable.refetch()
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        this.state[field] = 'success'
        this.dialog = false
      }
    }
  }
}
</script>

<style>
  .v-label {
    font-size: 13px !important;
  }
</style>
