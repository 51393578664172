var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.stocks.nodes && _vm.stocks.nodes.length
        ? _c("v-data-table", {
            staticClass: "app-table mt-4",
            attrs: {
              headers: _vm.headers,
              items: _vm.stocks.nodes,
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header.title",
                  fn: function(ref) {
                    var header = ref.header
                    return [_vm._v(" " + _vm._s(header.text) + " ")]
                  }
                },
                {
                  key: "header.type",
                  fn: function(ref) {
                    var header = ref.header
                    return [_vm._v(" " + _vm._s(header.text) + " ")]
                  }
                },
                {
                  key: "item.title",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", [_vm._v(" " + _vm._s(item.name) + " ")]),
                          _c("warehouse-update", {
                            attrs: { "stock-id": item.id },
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.type",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.isMain
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "my-4",
                                  attrs: { disabled: "", outlined: "" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("warehouse.table.mainStock")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      icon: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "warehouse.tooltip.warehouseInformation"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "warehouse.tooltip.moreInformation"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "my-4",
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openProductAnyWarehouse(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("warehouse.table.productInStock")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ]
                  }
                },
                {
                  key: "item.edit",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-4",
                          attrs: {
                            to: "/connector",
                            color: "primary",
                            outlined: ""
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("warehouse.btn.connectMarketplace")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.delete",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      !item.isMain
                        ? _c("warehouse-remove", {
                            attrs: { "stock-id": item.id },
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _c("v-pagination", {
        attrs: { length: _vm.stocks.pagesCount, "total-visible": 7 },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }