var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stock.stockProducts
    ? _c(
        "div",
        [
          _vm.stock.stockProducts.nodes.length
            ? _c("v-simple-table", {
                staticClass: "app-table mb-4",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("warehouse.table.header.productId")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "warehouse.table.header.productName"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th")
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.stock.stockProducts.nodes, function(
                              item
                            ) {
                              return _c(
                                "tr",
                                { key: "stock_product-" + item.id },
                                [
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(item.product.code) + " "
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(item.product.name) + " "
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("product-remove", {
                                        attrs: { "stock-product-id": item.id },
                                        on: {
                                          submit: function($event) {
                                            return _vm.refetch()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2259383292
                )
              })
            : _vm._e(),
          _vm.stock.stockProducts.nodes.length
            ? _c("v-pagination", {
                attrs: {
                  length: _vm.stock.stockProducts.pagesCount,
                  "total-visible": 7
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }