<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900"
      @click:outside="closeWarehouseUpdate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          small
          v-bind="attrs"
          v-on="on">
          <v-icon small>
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card style="position: relative;">
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              icon
              @click="closeWarehouseUpdate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('warehouse.label.editWarehouse')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-4"
            lazy-validation
            @submit.prevent="submit()">
            <warehouse-form :form="form" />
          </v-form>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              text
              @click="closeWarehouseUpdate()">
              {{ $t('warehouse.btn.cancel') }}
            </v-btn>
            <save-change-button
              class="mr-1"
              :state="state"
              :disabled="!valid"
              :label="$t('warehouse.btn.save')"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WarehouseForm from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseForm'
import { GET_STOCK } from '@/resources/graphql/query/Stock'
import { UPDATE_STOCK } from '@/resources/graphql'

export default {
  name: 'WarehouseUpdate',
  apollo: {
    stock: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCK,
      variables () {
        return {
          id: this.stockId
        }
      },
      result ({ data: { stock } }) {
        this.form.name = stock.name
        this.form.address = stock.address
        this.form.orderNumberPrefix = stock.orderNumberPrefix
        this.form.orderSummaryText = stock.orderSummaryText
        this.form.taxId = stock.taxId
        this.form.tel = stock.tel
        this.form.facebookPageUid = stock.facebookPageUid
      }
    })
  },
  components: {
    WarehouseForm
  },
  props: {
    stockId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      stock: {},
      form: {
        name: '',
        address: '',
        orderNumberPrefix: '',
        orderSummaryText: '',
        taxId: '',
        tel: '',
        facebookPageUid: ''
      }
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeWarehouseUpdate () {
      this.dialog = false
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_STOCK,
          variables: {
            input: {
              id: this.stockId,
              name: this.form.name,
              address: this.form.address,
              orderNumberPrefix: this.form.orderNumberPrefix,
              orderSummaryText: this.form.orderSummaryText,
              taxId: this.form.taxId,
              tel: this.form.tel,
              facebookPageUid: this.form.facebookPageUid
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateStock } } = res
          if (updateStock.errors && Object.keys(updateStock.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    }
  }
}
</script>
