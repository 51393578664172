<template>
  <div>
    <v-data-table
      v-if="stocks.nodes && stocks.nodes.length"
      :headers="headers"
      :items="stocks.nodes"
      class="app-table mt-4"
      hide-default-footer>
      <template v-slot:[`header.title`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.type`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <div class="d-flex align-center">
          <span>
            {{ item.name }}
          </span>
          <warehouse-update
            :stock-id="item.id"
            @submit="refetch()">
          </warehouse-update>
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div v-if="item.isMain">
          <v-btn
            class="my-4"
            disabled
            outlined>
            {{ $t('warehouse.table.mainStock') }}
          </v-btn>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-help-circle-outline
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('warehouse.tooltip.warehouseInformation') }}
              <br>
              {{ $t('warehouse.tooltip.moreInformation') }}
            </span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-btn
            color="primary"
            outlined
            class="my-4"
            @click="openProductAnyWarehouse(item)">
            {{ $t('warehouse.table.productInStock') }}
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.edit`]>
        <v-btn
          to="/connector"
          color="primary"
          outlined
          class="my-4">
          {{ $t('warehouse.btn.connectMarketplace') }}
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <warehouse-remove
          v-if="!item.isMain"
          :stock-id="item.id"
          @submit="refetch()">
        </warehouse-remove>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="stocks.pagesCount"
      :total-visible="7">
    </v-pagination>
  </div>
</template>

<script>
import WarehouseUpdate from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseUpdate'
import WarehouseRemove from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseRemove'
import { GET_STOCKS } from '@/resources/graphql/query/Stocks'

export default {
  name: 'WarehouseDataTable',
  apollo: {
    stocks: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCKS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result ({ data: { stocks } }) {
        if (this.page > stocks.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    WarehouseUpdate,
    WarehouseRemove
  },
  props: {
    stockId: {
      type: [String, Number],
      default: null
    },
    activeProduct: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      perPage: 5,
      stocks: [],
      headers: [
        {
          text: this.$t('warehouse.table.header.warehouseName'),
          align: 'start',
          value: 'title',
          sortable: false
        },
        {
          text: this.$t('warehouse.table.header.warehouseDetail'),
          align: 'start',
          value: 'type',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          value: 'edit',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          value: 'delete',
          sortable: false
        }
      ]
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.stocks.refetch()
    },
    openProductAnyWarehouse (item) {
      this.$emit('update:stockId', item.id)
      this.$emit('update:activeProduct', true)
    }
  }
}
</script>

<style>

</style>
