var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouse" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("warehouse.header"),
              "prepend-icon": "$warehouseHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("warehouse.label.moreOption"),
                  "prepend-icon": "$settingTitle"
                }
              }),
              _c("toggle-form", {
                attrs: {
                  text: _vm.$t("warehouse.toggle.shareInventoryBetweenStock"),
                  state: _vm.state.shareInventoryBetweenStock
                },
                on: {
                  input: function($event) {
                    return _vm.openStockAlert()
                  }
                },
                model: {
                  value: _vm.setting.shareInventoryBetweenStock,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "shareInventoryBetweenStock", $$v)
                  },
                  expression: "setting.shareInventoryBetweenStock"
                }
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "450", persistent: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pt-4" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "3" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary", size: "60" } },
                                    [_vm._v(" mdi-information-outline ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pt-4" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "primary--text subtitle-1" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("warehouse.alert.label")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "warehouse.alert.moveToMainStock"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-form",
                                    { ref: "form" },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: _vm.$t(
                                            "warehouse.textField.showAlert.label"
                                          ),
                                          rules: _vm.stockAletRules,
                                          required: ""
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "text-right" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.closeStockAlert()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warehouse.btn.cancel"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                rounded: "",
                                                depressed: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.submitStockAlert()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warehouse.btn.confirm"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm.activeProduct
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c("title-label", {
                            attrs: {
                              label: _vm.$t(
                                "warehouse.label.productInWarehouse"
                              ),
                              "prepend-icon": "mdi-warehouse"
                            }
                          }),
                          _c(
                            "span",
                            { staticClass: "primary--text ml-2 mt-1" },
                            [_vm._v(" " + _vm._s() + " ")]
                          ),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" mdi-chevron-left ")
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "primary--text mt-1",
                              on: {
                                click: function($event) {
                                  return _vm.backToWarehouse()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("warehouse.btn.back")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "warehouse.search.placeholder"
                                  ),
                                  "prepend-inner-icon": "mdi-magnify",
                                  dense: "",
                                  outlined: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c("product-create", {
                                attrs: { "stock-id": _vm.stockId },
                                on: {
                                  submit: function($event) {
                                    return _vm.refetchStockProduct()
                                  }
                                }
                              }),
                              _c("product-import", {
                                attrs: { "stock-id": _vm.stockId },
                                on: {
                                  submit: function($event) {
                                    return _vm.refetchStockProduct()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t("warehouse.label.warehouseManage"),
                          "prepend-icon": "$stockTitle"
                        }
                      }),
                      _c("warehouse-create", {
                        on: {
                          submit: function($event) {
                            return _vm.refetchStock()
                          }
                        }
                      })
                    ],
                    1
                  ),
              _vm.activeProduct
                ? _c("product-simple-table", {
                    ref: "StockProductSimpleTable",
                    attrs: { search: _vm.search, "stock-id": _vm.stockId }
                  })
                : _c("warehouse-data-table", {
                    ref: "StockDataTable",
                    attrs: {
                      "active-product": _vm.activeProduct,
                      "stock-id": _vm.stockId
                    },
                    on: {
                      "update:activeProduct": function($event) {
                        _vm.activeProduct = $event
                      },
                      "update:active-product": function($event) {
                        _vm.activeProduct = $event
                      },
                      "update:stockId": function($event) {
                        _vm.stockId = $event
                      },
                      "update:stock-id": function($event) {
                        _vm.stockId = $event
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }