import gql from 'graphql-tag'

export const GET_STOCK = gql`
  query ($id: ID!) {
    stock(id: $id) {
      id
      name
      address
      orderNumberPrefix
      orderSummaryText
      taxId
      tel
      facebookPageUid
    }
  }
`

export const GET_STOCK_PRODUCT = gql`
  query ($id: ID!, $page: Int, $perPage: Int, $search: String) {
    stock(id: $id) {
      id
      stockProducts(
        page: $page, 
        perPage: $perPage 
        filter: {
          search: $search
        }
      ) {
        nodes {
          id
          product {
            name
            code
          }
        }
        pagesCount
        nodesCount
      }
    }
  }
`

export default {
  GET_STOCK,
  GET_STOCK_PRODUCT
}
