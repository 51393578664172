import { render, staticRenderFns } from "./WarehouseForm.vue?vue&type=template&id=75574e88&"
import script from "./WarehouseForm.vue?vue&type=script&lang=js&"
export * from "./WarehouseForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VRow,VSelect,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75574e88')) {
      api.createRecord('75574e88', component.options)
    } else {
      api.reload('75574e88', component.options)
    }
    module.hot.accept("./WarehouseForm.vue?vue&type=template&id=75574e88&", function () {
      api.rerender('75574e88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseForm.vue"
export default component.exports