<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="550"
      @click:outside="closeProductImport()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="ml-4"
          outlined
          v-bind="attrs"
          v-on="on">
          {{ $t('warehouse.btn.importProduct') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-6">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeProductImport()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <title-label
              :label="$t('warehouse.label.importAnyCategory')" />
            <text-field-label
              :label="$t('warehouse.textField.category.label')"
              :required="false">
            </text-field-label>
            <v-combobox
              v-model="selectedProduct"
              :placeholder="$t('warehouse.textField.category.placeholder')"
              :items="productCategories"
              :rules="categoryRules"
              item-text="name"
              item-value="id"
              dense
              outlined
              required>
            </v-combobox>
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                text
                @click="closeProductImport()">
                {{ $t('warehouse.btn.cancel') }}
              </v-btn>
              <save-change-button
                :disabled="!valid"
                :state="state"
                :label="$t('warehouse.btn.confirm')"
                class="mr-1"
                @click="submit()">
              </save-change-button>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_PRODUCT_CATEGORIES } from '@/resources/graphql/query/ProductCategories'
import { IMPORT_STOCKPRODUCT } from '@/resources/graphql'

export default {
  name: 'ProductCreate',
  apollo: {
    productCategories: () => ({
      fetchPolicy: 'no-cache',
      query: GET_PRODUCT_CATEGORIES,
      result () {
        this.productCategories.unshift({
          id: '',
          name: 'สินค้าทั้งหมด'
        })
      }
    })
  },
  props: {
    stockId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      selectedProduct: '',
      state: 'ready',
      valid: true,
      categoryRules: [
        (v) => !!v || this.$t('warehouse.textField.category.validate.required')
      ]
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeProductImport () {
      this.dialog = false
      this.selectedProduct = ''
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: IMPORT_STOCKPRODUCT,
          variables: {
            input: {
              id: this.stockId,
              productCategoryId: this.selectedProduct.id
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.$emit('submit')
          this.state = 'success'
          this.dialog = false
          this.selectedProduct = ''
          this.$refs.form.resetValidation()
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 1500)
      }
    }
  }
}
</script>
