var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550" },
          on: {
            "click:outside": function($event) {
              return _vm.closeProductImport()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", outlined: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("warehouse.btn.importProduct")) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-6" },
                [
                  _c(
                    "div",
                    { staticClass: "close-popup" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeProductImport()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "mt-3",
                      attrs: { readonly: _vm.loading, "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("title-label", {
                        attrs: {
                          label: _vm.$t("warehouse.label.importAnyCategory")
                        }
                      }),
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("warehouse.textField.category.label"),
                          required: false
                        }
                      }),
                      _c("v-combobox", {
                        attrs: {
                          placeholder: _vm.$t(
                            "warehouse.textField.category.placeholder"
                          ),
                          items: _vm.productCategories,
                          rules: _vm.categoryRules,
                          "item-text": "name",
                          "item-value": "id",
                          dense: "",
                          outlined: "",
                          required: ""
                        },
                        model: {
                          value: _vm.selectedProduct,
                          callback: function($$v) {
                            _vm.selectedProduct = $$v
                          },
                          expression: "selectedProduct"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeProductImport()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("warehouse.btn.cancel")) +
                                  " "
                              )
                            ]
                          ),
                          _c("save-change-button", {
                            staticClass: "mr-1",
                            attrs: {
                              disabled: !_vm.valid,
                              state: _vm.state,
                              label: _vm.$t("warehouse.btn.confirm")
                            },
                            on: {
                              click: function($event) {
                                return _vm.submit()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }