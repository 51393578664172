<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900"
      @click:outside="closeWarehouseCreate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="totalStock === setting.maxStocks"
          v-bind="attrs"
          color="primary"
          class="ml-4"
          rounded
          depressed
          v-on="on">
          <v-icon> mdi-plus-circle </v-icon>
          <span>
            {{ $t('warehouse.btn.addWarehouse') }}
          </span>
        </v-btn>
      </template>
      <v-card style="position: relative;">
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              icon
              @click="closeWarehouseCreate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('warehouse.label.addWarehouse')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-4"
            lazy-validation
            @submit.prevent="submit()">
            <warehouse-form :form="form" />
          </v-form>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              text
              @click="closeWarehouseCreate()">
              {{ $t('warehouse.btn.cancel') }}
            </v-btn>
            <save-change-button
              :state="state"
              :disabled="!valid"
              :label="$t('warehouse.btn.confirm')"
              class="mr-1"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WarehouseForm from '@/views/ShopSetting/Pages/Warehouse/components/Warehouse/WarehouseForm'
import { CREATE_STOCK, GET_STOCKS } from '@/resources/graphql'
import { GET_SETTING_SHOPINFO } from '@/resources/graphql/query/Setting'

export default {
  name: 'WarehouseCreate',
  apollo: {
    stocks: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCKS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result ({ data: { stocks } }) {
        this.totalStock = stocks.nodes.length
      }
    }),
    setting: () => ({
      query: GET_SETTING_SHOPINFO
    })
  },
  components: {
    WarehouseForm
  },
  data () {
    return {
      page: 1,
      perPage: 5,
      totalStock: 0,
      dialog: false,
      valid: true,
      stocks: [],
      setting: {},
      form: {
        name: '',
        address: '',
        orderNumberPrefix: '',
        orderSummaryText: '',
        taxId: '',
        tel: '',
        facebookPageUid: ''
      },
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_STOCK,
          variables: {
            input: {
              name: this.form.name,
              address: this.form.address,
              orderNumberPrefix: this.form.orderNumberPrefix,
              orderSummaryText: this.form.orderSummaryText,
              taxId: this.form.taxId,
              tel: this.form.tel,
              facebookPageUid: this.form.facebookPageUid
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.dialog = false
          this.$emit('submit')
          this.resetForm()
          this.state = 'success'
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    },
    closeWarehouseCreate () {
      this.dialog = false
      this.resetForm()
      this.$refs.form.resetValidation()
    },
    resetForm () {
      this.form = {
        name: '',
        address: '',
        orderNumberPrefix: '',
        orderSummaryText: '',
        taxId: '',
        tel: '',
        facebookPageUid: ''
      }
    }
  }
}
</script>
