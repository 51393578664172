import gql from 'graphql-tag'

export const GET_PRODUCT_CATEGORIES = gql`
  query {
    productCategories {
      id
      name
    }
  }
`

export default {
  GET_PRODUCT_CATEGORIES
}
