<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="7"
        class="mr-8">
        <label>
          {{ $t('warehouse.textField.warehouseName.label') }}
          <span class="red--text"> * </span>
        </label>
        <v-text-field
          v-model="form.name"
          :placeholder="$t('warehouse.textField.warehouseName.placeholder')"
          :rules="nameRules"
          class="mt-4"
          dense
          outlined
          required />
        <label>
          {{ $t('warehouse.textField.address.label') }}
        </label>
        <v-textarea
          v-model="form.address"
          :placeholder="$t('warehouse.textField.address.placeholder')"
          class="mt-4"
          dense
          outlined
          no-resize>
        </v-textarea>
        <label>
          {{ $t('warehouse.textField.summaryText.label') }}
        </label>
        <v-textarea
          v-model="form.orderSummaryText"
          :placeholder="$t('warehouse.textField.summaryText.placeholder')"
          class="mt-4"
          dense
          outlined
          no-resize>
        </v-textarea>
      </v-col>
      <v-col class="d-flex flex-column justify-space-between">
        <div>
          <label>
            {{ $t('warehouse.textField.taxId.label') }}
          </label>
          <v-text-field
            v-model="form.taxId"
            :placeholder="$t('warehouse.textField.taxId.placeholder')"
            class="mt-4"
            dense
            outlined />
          <label>
            {{ $t('warehouse.textField.telephone.label') }}
          </label>
          <v-text-field
            v-model="form.tel"
            :placeholder="$t('warehouse.textField.telephone.placeholder')"
            dense
            outlined />
        </div>
        <div>
          <label>
            {{ $t('warehouse.textField.prefixOrder.label') }}
          </label>
          <v-text-field
            v-model="form.orderNumberPrefix"
            :placeholder="$t('warehouse.textField.prefixOrder.placeholder')"
            class="mt-4"
            dense
            outlined />
          <label>
            {{ $t('warehouse.textField.selectPage.label') }}
          </label>
          <v-select
            v-if="setting.facebookPages"
            v-model="form.facebookPageUid"
            :items="setting.facebookPages.pages"
            item-text="name"
            item-value="uid"
            :menu-props="{ top: true, offsetY: true }"
            attach
            dense
            outlined>
          </v-select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GET_SETTING_STOCK } from '@/resources/graphql/query/Setting'

export default {
  name: 'WarehouseForm',
  apollo: {
    setting: () => ({
      query: GET_SETTING_STOCK,
      result () {
        this.setting.facebookPages.pages.unshift({
          name: 'ทุกเพจ',
          uid: ''
        })
      }
    })
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      setting: {},
      nameRules: [
        (v) => !!v || this.$t('warehouse.textField.warehouseName.validate.required')
      ]
      // addressRules: [
      //   (v) => !!v || this.$t('warehouse.textField.address.validate.required')
      // ],
      // textRules: [
      //   (v) => !!v || this.$t('warehouse.textField.summaryText.validate.required')
      // ],
      // taxIdRules: [
      //   (v) => !!v || this.$t('warehouse.textField.taxId.validate.required')
      // ],
      // telephoneRules: [
      //   (v) => !!v || this.$t('warehouse.textField.telephone.validate.required')
      // ],
      // prefixRules: [
      //   (v) => !!v || this.$t('warehouse.textField.prefixOrder.validate.required')
      // ]
    }
  }
}
</script>
