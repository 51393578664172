<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="550"
      @click:outside="closeProductCreate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on">
          {{ $t('warehouse.btn.addProduct') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-6">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeProductCreate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <title-label :label="$t('warehouse.label.createNewProduct')" />
            <text-field-label
              :label="$t('warehouse.textField.product.label')"
              :required="false">
            </text-field-label>
            <v-autocomplete
              v-model="selectedProductId"
              :items="products.nodes"
              :placeholder="$t('warehouse.textField.product.placeholder')"
              :rules="productRules"
              item-text="name"
              item-value="id"
              dense
              outlined
              required
              @update:search-input="debounceSearch($event)" />
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                text
                @click="closeProductCreate()">
                {{ $t('warehouse.btn.cancel') }}
              </v-btn>
              <save-change-button
                :disabled="!valid"
                :state="state"
                :label="$t('warehouse.btn.confirm')"
                class="mr-1"
                @click="submit()">
              </save-change-button>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_PRODUCTS } from '@/resources/graphql/query/Products'
import { CREATE_STOCKPRODUCT } from '@/resources/graphql'
import _ from 'lodash'

export default {
  name: 'ProductCreate',
  apollo: {
    products: () => ({
      query: GET_PRODUCTS,
      variables () {
        return {
          perPage: 4000,
          q: this.q
        }
      }
    })
  },
  props: {
    stockId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      products: [],
      q: '',
      selectedProductId: '',
      state: 'ready',
      valid: true,
      productRules: [
        (v) => !!v || this.$t('warehouse.textField.product.validate.required')
      ]
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    debounceSearch: _.debounce(function (value) {
      this.q = value
    }, 800),
    closeProductCreate () {
      this.dialog = false
      this.selectedProductId = ''
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_STOCKPRODUCT,
          variables: {
            input: {
              productId: this.selectedProductId,
              stockId: this.stockId
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.$emit('submit')
          this.state = 'success'
          this.dialog = false
          this.selectedProduct = ''
          this.$refs.form.resetValidation()
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 1500)
      }
    }
  }
}
</script>
