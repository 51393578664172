var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-8", attrs: { cols: "7" } },
            [
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("warehouse.textField.warehouseName.label")) +
                    " "
                ),
                _c("span", { staticClass: "red--text" }, [_vm._v(" * ")])
              ]),
              _c("v-text-field", {
                staticClass: "mt-4",
                attrs: {
                  placeholder: _vm.$t(
                    "warehouse.textField.warehouseName.placeholder"
                  ),
                  rules: _vm.nameRules,
                  dense: "",
                  outlined: "",
                  required: ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("warehouse.textField.address.label")) +
                    " "
                )
              ]),
              _c("v-textarea", {
                staticClass: "mt-4",
                attrs: {
                  placeholder: _vm.$t(
                    "warehouse.textField.address.placeholder"
                  ),
                  dense: "",
                  outlined: "",
                  "no-resize": ""
                },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              }),
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("warehouse.textField.summaryText.label")) +
                    " "
                )
              ]),
              _c("v-textarea", {
                staticClass: "mt-4",
                attrs: {
                  placeholder: _vm.$t(
                    "warehouse.textField.summaryText.placeholder"
                  ),
                  dense: "",
                  outlined: "",
                  "no-resize": ""
                },
                model: {
                  value: _vm.form.orderSummaryText,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderSummaryText", $$v)
                  },
                  expression: "form.orderSummaryText"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex flex-column justify-space-between" },
            [
              _c(
                "div",
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("warehouse.textField.taxId.label")) +
                        " "
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      placeholder: _vm.$t(
                        "warehouse.textField.taxId.placeholder"
                      ),
                      dense: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.form.taxId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "taxId", $$v)
                      },
                      expression: "form.taxId"
                    }
                  }),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("warehouse.textField.telephone.label")) +
                        " "
                    )
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.$t(
                        "warehouse.textField.telephone.placeholder"
                      ),
                      dense: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("warehouse.textField.prefixOrder.label")
                        ) +
                        " "
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      placeholder: _vm.$t(
                        "warehouse.textField.prefixOrder.placeholder"
                      ),
                      dense: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.form.orderNumberPrefix,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "orderNumberPrefix", $$v)
                      },
                      expression: "form.orderNumberPrefix"
                    }
                  }),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("warehouse.textField.selectPage.label")) +
                        " "
                    )
                  ]),
                  _vm.setting.facebookPages
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.setting.facebookPages.pages,
                          "item-text": "name",
                          "item-value": "uid",
                          "menu-props": { top: true, offsetY: true },
                          attach: "",
                          dense: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.form.facebookPageUid,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "facebookPageUid", $$v)
                          },
                          expression: "form.facebookPageUid"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }