<template>
  <div v-if="stock.stockProducts">
    <v-simple-table
      v-if="stock.stockProducts.nodes.length"
      class="app-table mb-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('warehouse.table.header.productId') }}
            </th>
            <th class="text-left">
              {{ $t('warehouse.table.header.productName') }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in stock.stockProducts.nodes"
            :key="`stock_product-${item.id}`">
            <td>
              {{ item.product.code }}
            </td>
            <td>
              {{ item.product.name }}
            </td>
            <td>
              <product-remove
                :stock-product-id="item.id"
                @submit="refetch()">
              </product-remove>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="stock.stockProducts.nodes.length"
      v-model="page"
      :length="stock.stockProducts.pagesCount"
      :total-visible="7">
    </v-pagination>
  </div>
</template>

<script>
import ProductRemove from '@/views/ShopSetting/Pages/Warehouse/components/Product/ProductRemove'
import { GET_STOCK_PRODUCT } from '@/resources/graphql/query/Stock'

export default {
  name: 'ProductSimpleTable',
  apollo: {
    stock: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCK_PRODUCT,
      variables () {
        return {
          id: this.stockId,
          page: this.page,
          perPage: this.perPage,
          search: this.search
        }
      },
      result ({ data: { stock } }) {
        if (this.page > stock.stockProducts.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    ProductRemove
  },
  props: {
    stockId: {
      type: [String, Number],
      default: null
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      page: 1,
      perPage: 5,
      stock: []
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.stock.refetch()
    }
  }
}
</script>
